import React from "react"
import { Link } from "gatsby"
// Components
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import ContactForm from "../components/ContactForm"
import { Seo } from "../components/seo"
import { graphql } from "gatsby"
const ArticleTemplate = ({ data }) => {
  const {
    Title,
    DatePublication,
    Content,
    LastUpdate,
    article_categories,
  } = data.allStrapiTitles.nodes[0]

  return (
    <section className="container article-template">
      <div className="row">
        <div className="col-lg-9 col-lg-9">
          <div className="article-template__head">
            <SectionTitle
              dangerouslySetInnerHTML={{ __html: Title }}
            ></SectionTitle>
            <hr className="u-line-bottom" />
            <p className="info-date">
              投稿日 : {DatePublication} &emsp; 更新日 : {LastUpdate} &emsp;
              カテゴリー :
              {article_categories.length === 0 ? (
                ""
              ) : (
                <>
                  {article_categories.map(cat => (
                    <Link to={`/info-cat/${cat.Slug}`} className="linked-tag">
                      {cat.Name}
                    </Link>
                  ))}
                </>
              )}
            </p>
          </div>
          {/* {Image ? (
              <img
                className="article-template__banner-image"
                src={Image.url}
                alt={Title}
              />
            ) : (
              ""
            )} */}
          {/* <p className="article-template__description">{Description}</p> */}
          {/*  */}
          {/*  */}
          <div
            className="article-template__inner-html "
            dangerouslySetInnerHTML={{ __html: Content }}
          ></div>

          <p className="article-template__info">
            電話番号：+52-449-107-5308 <br />
            メールアドレス：{" "}
            <a
              href="mailto:quick@919mexico.com"
              target="_blank"
              rel="noreferrer"
            >
              quick@919mexico.com
            </a>
            <br />
          </p>
          <ContactForm />
        </div>
        <WrapperRight />
      </div>
    </section>
  )
}

export default ArticleTemplate

export const Head = ({ data }) => {
  const {
    Title,
    Slug,
    MetaDescription,
    keywords,
    Image,
  } = data.allStrapiTitles.nodes[0]
  return (
    <Seo
      title={`${Title}`}
      image={Image ? Image.url : ""}
      description={MetaDescription}
      author={`919mexico.com`}
      url={`https://www.919mexico.com/info/${
        Slug !== null ? Slug : "https://www.919mexico.com/info"
      }`}
      keywords={[keywords]}
    />
  )
}

export const query = graphql`
  query ArticlesQuery($slug: String) {
    allStrapiTitles(filter: { Slug: { eq: $slug } }) {
      nodes {
        Title
        Description
        DatePublication(formatString: "YYYY年 MM 月 DD日")
        LastUpdate(formatString: "YYYY年 MM 月 DD日")
        Slug
        MetaDescription
        keywords
        Image {
          url
        }
        Content
        article_categories {
          Name
          Slug
        }
      }
    }
  }
`
