import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Recaptcha from "react-recaptcha"
import { Input } from "../components/styled"
import { useMutation } from "@apollo/client"
import { NewContact } from "../crud/createRegister"
import { toast } from "react-toastify"
import useSSR from "use-ssr"
import axios from "axios"
const ContactForm = () => {
  const { isBrowser } = useSSR()
  const [contact, setContact] = useState({
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    PhoneNumber: "0",
    QueryContent: "",
    messageError: {},
    isVerified: false,
  })
  useEffect(() => {
    getIpInfo()
  })
  const [ip, setIp] = useState("No")
  const [ipAddress, setIpAddress] = useState("No")
  const getIpInfo = async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    setIp(res.data.IPv4)
    setIpAddress(res.data.country_name)
  }
  const [loading, setLoading] = useState(false)
  const { messageError } = contact
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const handleChange = e => {
    const { name, value } = e.target
    setContact(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = contact.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          value !== contact.Email
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : ""
        break
      case "QueryContent":
        errors.QueryContent =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }
  const [addContact] = useMutation(NewContact, {
    onCompleted: () => {
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterContact = () => {
    addContact({
      variables: {
        input: {
          data: {
            FullName: contact.FullName,
            Email: contact.Email,
            PhoneNumber: contact.PhoneNumber,
            QueryContent: contact.QueryContent,
            IpAddress: ip,
            IpCountry: ipAddress,
          },
        },
      },
    })
  }
  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setContact(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    // if (contact.isVerified) {
    if (
      contact.FullName !== "" &&
      contact.Email !== "" &&
      contact.ConfirmEmail !== "" &&
      contact.QueryContent !== ""
    ) {
      if (formValidate(contact.messageError, contact)) {
        setLoading(true)
        handleRegisterContact()
      } else {
        toast.error("フォームが正しくありません")
      }
    } else {
      toast.error("入力内容を再度ご確認ください。")
    }
    // } else {
    //   // recaptchaVerify()
    // }
  }

  return (
    <form
      className="contact-us__form-contact form-responsive"
      onSubmit={handleSubmit}
    >
      <div className="form-responsive">
        <Input>
          <label htmlFor="氏名（フルネーム">
            <p className="form-label">氏名（フルネーム）</p> <span>必須</span>
          </label>
          <input
            type="text"
            placeholder="記入例：山田太郎 Yamada Taro"
            id="氏名（フルネーム"
            name="FullName"
            onChange={handleChange}
            onBlur={handleChange}
          />
          {messageError.FullName && (
            <span className="field-validation">{messageError.FullName}</span>
          )}
        </Input>
        <Input>
          <label htmlFor="メールアドレス">
            <p className="form-label">メールアドレス</p> <span>必須</span>
          </label>
          <input
            type="text"
            id="メールアドレス"
            name="Email"
            onChange={handleChange}
            onBlur={handleChange}
          />
          {messageError.Email && (
            <span className="field-validation">{messageError.Email}</span>
          )}
        </Input>
        <Input>
          <label htmlFor="メールアドレスの再入力">
            <p className="form-label">メールアドレスの再入力</p>{" "}
            <span>必須</span>
          </label>
          <input
            type="text"
            id="メールアドレスの再入力"
            name="ConfirmEmail"
            onChange={handleChange}
            onBlur={handleChange}
          />
          {messageError.ConfirmEmail && (
            <span className="field-validation">
              {messageError.ConfirmEmail}
            </span>
          )}
        </Input>
        <Input>
          <label htmlFor="電話番号">
            <p className="form-label">電話番号</p>
          </label>
          <input
            type="text"
            id="電話番号"
            name="PhoneNumber"
            onChange={handleChange}
          />
        </Input>
        <Input>
          <label htmlFor="お問い合わせ内容">
            <p className="form-label">お問い合わせ内容</p> <span>必須</span>
          </label>
          <textarea
            id="お問い合わせ内容"
            name="QueryContent"
            onChange={handleChange}
            onBlur={handleChange}
          />
          {messageError.QueryContent && (
            <span className="field-validation">
              {messageError.QueryContent}
            </span>
          )}
        </Input>
        <div className="captcha-style">
          {isBrowser && (
            <Recaptcha
              render="explicit"
              name="isVerify"
              verifyCallback={recaptchaVerify}
              sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
            />
          )}
          {captchaVerify ? (
            <span className="field-validation-captcha">
              Please verify that you are not a robot
            </span>
          ) : (
            ""
          )}
        </div>
        <p className="no-padding">
          当社の
          <a
            className="btn-terms-condition"
            href="/termsofuse/"
            target="_blank"
            rel="noreferrer"
          >
            利用規約・個人情報保護方針
          </a>
          に同意の上、お申込みください。
        </p>
        <div className="u-text-center">
          {loading === false ? (
            <input type="submit" value="申し込む" className="btn-submit" />
          ) : (
            <p className="buttonload">
              <i className="fa fa-circle-o-notch fa-spin"></i>
              &nbsp;&nbsp;少々お待ちください
            </p>
          )}
        </div>
      </div>
    </form>
  )
}

export default ContactForm
